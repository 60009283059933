import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import ProductId from '@/common/ProductId';
import { RootState } from '@/rootStateTypes';

import {
  DealTilePositioningVariables,
  DetermineNewPriorityForDeal,
  HandleDealPriorityConflicts,
  UseDealTilePositioning,
  CheckForExistingRule,
  ResetDealsPriority,
  Deal
} from './types';

/**
 * @function determineNewPriorityForDeal
 * @description determines the new priority for a matched deal based on priority set in optimizely.
 */
const determineNewPriorityForDeal = ({ priority, deals }: DetermineNewPriorityForDeal) => {
  if (typeof priority === 'number') {
    return +priority;
  }
  if (priority === 'bottom') {
    return deals.length + 1;
  }
  if (priority === 'middle') {
    return Math.floor(deals.length / 2);
  }
  return 0;
};

/**
 * @function checkForExistingRule
 * @description determines if a deal's product code is set in optimizely to be re-prioritized.
 */
const checkForExistingRule = ({ rules, deal, isYum }: CheckForExistingRule) => rules.find((rule) => {
  const dealCodes = isYum ? rule.yum : rule.nonYum;
  const { globalId: dealId } = new ProductId(deal.id);

  return dealCodes.includes(dealId);
});

/**
 * @function resetDealsPriority
 * @description reset each unmatched deal's priority so we work with properly sequenced numbers instead of
 *  deals having a high priority like 990, 1002, 1220 etc...or deals having 0 priority (yum)
 * @note - deals are already sorted before this so we are not "overriding" the initial sorting.
 */
const resetDealsPriority = ({ deals }: ResetDealsPriority) => deals.map((deal, index) => ({
  ...deal,
  priority: index + 1
}));

/**
 * @function handleDealPriorityConflicts
 * @description handles deals having the same priority once a matched deal is re-proritized. We want
 * to keep priorites unqiue.
 */
const handleDealPriorityConflicts = ({ deals }: HandleDealPriorityConflicts) => {
  const uniquePriorities = new Set<number>();

  return deals.map((deal) => {
    let { priority } = deal;
    while (uniquePriorities.has(priority)) {
      priority += 1;
    }
    uniquePriorities.add(priority);

    return {
      ...deal,
      priority
    };
  });
};

const useDealTilePositioning = ({ deals }: UseDealTilePositioning) => {
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{
    enabled: decisionEnabled,
    variables: decisionVariables
  }] = useDecision('cb-deal_tile_positioning', { autoUpdate: true });

  const prioritizedDeals = useMemo(() => {
    // return deals as is if FF is not enabled
    if (!decisionEnabled) {
      return deals;
    }

    // get deal priority rules from FF
    const variables = decisionVariables as DealTilePositioningVariables;
    const dealPriorityRules = variables.deal_priority_rules?.deals;

    const dealsCopy = [...deals];

    // yum deals do not have a priority and ultimately get sorted by name but that sorting needs to happen before our logic runs.
    if (isYumEcomm) {
      dealsCopy.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    const dealsWithRules: Deal[] = []; // contains deals that need to be prioritized based on rules from FF
    const dealsWithoutRules: Deal[] = []; // contains deals that should be prioritzed as is from BE

    if (dealsCopy?.length) {
      dealsCopy.forEach((deal) => {
        const existingRule = checkForExistingRule({
          rules: dealPriorityRules,
          deal,
          isYum: isYumEcomm
        });

        if (existingRule) {
          const dealCopy = { ...deal };

          const newPriority = determineNewPriorityForDeal({
            priority: existingRule.priority,
            deals: dealsCopy
          });

          dealCopy.priority = newPriority;

          dealsWithRules.push(dealCopy);
        } else {
          dealsWithoutRules.push(deal);
        }
      });

      // if there are no matching deal product codes between BE & optimizely, return deals as is since we don't need to prioritize any.
      if (dealsWithRules.length === 0) {
        return dealsWithoutRules;
      }

      const dealsWithInitialPriorities = resetDealsPriority({
        deals: dealsWithoutRules
      });
      const allDeals = [...dealsWithRules, ...dealsWithInitialPriorities];

      const updatedDeals = handleDealPriorityConflicts({
        deals: allDeals
      });

      return updatedDeals.sort((a, b) => a.priority - b.priority);
    }

    return dealsCopy;
  }, [deals, decisionEnabled, decisionVariables, isYumEcomm]);

  return prioritizedDeals;
};

export default useDealTilePositioning;
