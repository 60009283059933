import React, { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { useRouter } from 'next/router';
import { sortBy } from 'remeda';
import { useDecision } from '@optimizely/react-sdk';
import MenuTileList from '../../MenuTileList';
import ProductId from '../../../common/ProductId';
import useDealMenu from './useDealMenu';
import { onEnteringDealsMenu } from '../analytics';
import telemetry from '@/telemetry';
import { formatTestId } from '@/common/string-formatter';
import { onDealAnalyticsClick } from '@/builders/deals/analytics/analytics';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import { setDealToDisplay } from '@/builders/deals/slice/dealToDisplay.slice';
import Routes from '@/router/routes';
import CategoryTitle from '@/common/components/CategoryTitle';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import { useHandleSuccessMenuLoadAfterError } from '@/hooks/useErrorHandling';
import { fromDealsMenu } from '@/builders/deals/slice/userSelections.slice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import LocalizedMenuSkeleton from '@/menu/deals/LocalizedMenu/LocalizedMenuSkeleton';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import isClientSide from '@/common/isClientSide';
import { LINEUP_GLOBALID } from '@/deals/constants';

const LocalizedMenu = (): JSX.Element | null => {
  const analytics = useAnalytics();
  const appInitialized = useApplicationInitialized();
  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);

  const dispatch = useDispatch();
  const router = useRouter();
  const { deals, loading } = useDealMenu();
  const successMenuLoadAfterRedirectError = useHandleSuccessMenuLoadAfterError();
  const [hideVluDecision] = useDecision('ops-web-1597-hide_vlu_tile', { autoUpdate: true });
  const optimizelyInstance = getOrInitializeOptimizely({ datafile: {}, isBrowser: isClientSide(), allowInitialize: false });

  const dealsToDisplay = hideVluDecision.enabled ? deals.filter((deal) => {
    const { globalId } = new ProductId(deal.id);
    return globalId !== LINEUP_GLOBALID;
  }) : deals;

  useEffect(() => {
    if (appInitialized && !pageLoadAnalyticsPushed && dealsToDisplay.length) {
      const sortedDeals = sortBy(dealsToDisplay,
        (recipe) => recipe.priority,
        (recipe) => recipe.name.toLowerCase());
      analytics.push(() => onEnteringDealsMenu(sortedDeals));
      telemetry.addCustomEvent('localized-deal-menu-view-page');
      setPageLoadAnalyticsPushed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoadAnalyticsPushed, appInitialized, dealsToDisplay]);

  const onDealClick = (deal: MenuRecipe, index?: number) => {
    const { storeSpecificId } = new ProductId(deal.id);

    const dealId = storeSpecificId;
    analytics.push(() => onDealAnalyticsClick(dealId, deal, index));
    if (dealId.includes(LINEUP_GLOBALID)) {
      telemetry.addCustomEvent('localized-deal-menu-lineup-tile-click');
      optimizelyInstance?.track('ValueLineupClick');
      router.push(Routes.LINEUP);
      return;
    }

    telemetry.addCustomEvent(
      `localized-deal-menu-${formatTestId(deal.name)}-tile-click`
    );

    optimizelyInstance?.track('DealsTileClick');

    batch(() => {
      dispatch(fromDealsMenu());
      dispatch(setDealToDisplay({ dealId }));
    });
  };

  if (loading) {
    return <LocalizedMenuSkeleton />;
  }

  /*
   * Here the Deals Menu should have loaded successfully. The router replace is required for the
   * deals back button functionality to work after an error handling has happened.
   */
  successMenuLoadAfterRedirectError(dealsToDisplay.length > 0, () => {
    router.replace(Routes.DEALS, undefined, { shallow: true });
  });

  return (
    <Grid data-testid="deal-menu-page-localized">
      <CategoryTitle title="Deals" />
      <MenuTileList
        currentRoute={Routes.DEALS}
        testId="deals-localized-list"
        tileList={dealsToDisplay}
        handleClick={onDealClick}
        showPricePrefix
        isLocalized
        hasLinkEnabled
        hideDescription
        key={0}
      />
      <CaloriesDisclaimer />
    </Grid>
  );
};

export default LocalizedMenu;
