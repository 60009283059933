import { useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useRouter } from 'next/router';
import { ActionCreators } from 'redux-undo';
import { localizationSelectors } from '@/localization/localizationSelectors';
import {
  clearDealToDisplay
} from '@/builders/deals/slice/dealToDisplay.slice';
import { UserSelections } from '@/builders/deals/slice/dealTypes';
import { RootState } from '@/rootStateTypes';
import { redeemLoyaltyDeal } from '@/builders/deals/slice/userSelections.slice';
import Routes from '@/router/routes';
import { clearDealGqlError, clearPizzaGqlError } from '@/graphql/errors/actions';

export const useClearDealOnStoreChange = (): void => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeNumber = storeDetails?.storeNumber;
  const dispatch = useDispatch();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!storeDetails?.storeNumber) return;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    dispatch(clearDealToDisplay());
    dispatch(ActionCreators.clearHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeNumber, dispatch]);
};

const getUrlParams = (url: string) => {
  const urlParams = url.split('?')[1];
  return new URLSearchParams(urlParams);
};

export function useClearDealOnRouteChange() {
  const router = useRouter();
  const dispatch = useDispatch();

  const clearRef = useRef(false);

  const handleRouteChangeComplete = (url?: string) => {
    if (!url || !getUrlParams(url).has('page')) {
      if (clearRef.current) {
        batch(() => {
          dispatch(clearDealGqlError());
          dispatch(clearPizzaGqlError());
          dispatch(clearDealToDisplay());
        });
      }
      dispatch(ActionCreators.clearHistory());
      dispatch(redeemLoyaltyDeal(false));
    }
  };

  const handleRouteChangeStart = (url: string) => {
    clearRef.current = url === Routes.DEALS;
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      handleRouteChangeComplete();
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useHistoryOnBrowserBack() {
  const dispatch = useDispatch();
  const router = useRouter();
  const history: UserSelections[] = useSelector(
    (state: RootState) => state.domain.deal.userSelections.past
  );

  useSetBrowserHistoryStackLength(history.length);

  useEffect(() => {
    router.beforePopState(() => {
      if (history.length > 0) {
        dispatch(ActionCreators.undo());
        return false;
      }
      return true;
    });
  }, [dispatch, router, history]);

  const handleRouteChangeStart = (url: string) => {
    const params = getUrlParams(url);
    const pageValue = parseInt(params.get('page') ?? '', 10);
    if (!Number.isNaN(pageValue)) {
      window.history.go(-pageValue);
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    if (router.asPath && !router.query.error) {
      router.replace(router.asPath, undefined, { shallow: true });
    }
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.beforePopState(() => true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const getStateWithParams = (newPage: number): Record<any, any> => {
  const oldParams = new URLSearchParams(window.location.search);
  const id = oldParams.get('id');
  let newParams = id ? `?id=${id}` : '';
  if (newPage > 0) {
    newParams += `${id ? '&' : '?'}page=${newPage}`;
  }
  const url = `${window.location.pathname}${newParams}`;

  return { ...window.history.state, as: url, url };
};

export function useSetBrowserHistoryStackLength(historyLength: number) {
  const browserHistoryStackLength = useRef(0);
  useEffect(() => {
    while (browserHistoryStackLength.current < historyLength) {
      browserHistoryStackLength.current += 1;
      const state = getStateWithParams(browserHistoryStackLength.current);
      window.history.pushState(state, document.title, state.url);
    }
    if (browserHistoryStackLength.current > historyLength) {
      browserHistoryStackLength.current = historyLength;
    }
    const state = getStateWithParams(browserHistoryStackLength.current);
    window.history.replaceState(state, '', state.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyLength]);
}
