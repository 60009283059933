import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import initializePage from '../../app/common/pageSetup';
import DealBuilder from '../../app/builders/deals/DealBuilder';
import Header from '../../app/header';
import Footer from '../../app/footer';
import { errorSelectors } from '@/graphql/errors/selectors';
import { localizationSelectors } from '@/localization/localizationSelectors';
import Menu from '../../app/menu/Menu';
import dealsData from '../../app/common/pageSetup/nationalData/dealsData';
import CenteredContainer from '../../app/common/CenteredContainer';
import Routes from '../../app/router/routes';
import LocalizedMenu from '../../app/menu/deals/LocalizedMenu';
import {
  clearDealToDisplay,
  selectDealToDisplay,
  setDealToDisplay
} from '@/builders/deals/slice/dealToDisplay.slice';
import dealService from '@/services/dealService';
import telemetry from '@/telemetry';
import logger from '@/common/logger';
import { openLocalizationRail, openModal } from '@/localization/actions';
import { dealNotAvailableModalDetails } from '@/common/Modal/modalDetails';
import { createItemAnalyticsForNationalDeals, onNationalDealMenuView } from '@/builders/deals/analytics/analytics';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import {
  useClearDealOnRouteChange,
  useClearDealOnStoreChange,
  useHistoryOnBrowserBack
} from '@/builders/deals/hooks/dealsPage';
import { useRedirectErrorHandling } from '@/hooks/useErrorHandling';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { WHITELISTED_DEALS } from '@/deals/constants';
import { ErrorPage } from '@/ErrorPage';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { openRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { cartSelectors } from '@/cart/cartSelectors';
import { CART_EMPTY_WARNING_MODAL, SIGN_IN_WARNING_MODAL } from '@/header/profile/constants';
import { RootState } from '@/rootStateTypes';
import {
  POS_PARSED_SERIALIZED_CODE_LENGTH, POS_SERIALIZED_CODE_LENGTH, QO_SERIALIZED_CODE_LENGTH, YUM_SERIALIZED_CODE_LENGTH
} from '@/clientCore/cart/constants';
import { useTemporaryCCContext } from '@/clientCore/temporaryTransformationalHooks/temporaryCCContext';

interface DealsProps {
  dealGlobalId?: string;
  ssrPrivateCode?: string | null;
  ssrError?: boolean;
  hasValidPrivateCode?: boolean;
  id: string | null;
}

type SerialCheckVariables = {
  deal_whitelist: {
    ids: string[];
  };
};

const Deals = ({
  dealGlobalId,
  ssrPrivateCode,
  ssrError,
  hasValidPrivateCode,
  id
}: DealsProps): JSX.Element => {
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{ enabled: isYumRoutingFixEnabled }] = useDecision('fr-web-3694-fix_yum_deal_routing');
  const [{ enabled: isYumHeroSideKickRoutingEnabled }] = useDecision('fr-web-3631-hero-sidekicks-routing-for-yum');
  const [isNewSerialCheckDecision] = useDecision('fr-web-3695-update_serial_logic');
  const { enabled: isNewSerialCheckEnabled } = isNewSerialCheckDecision;
  const newSerialWhitelistIds = (isNewSerialCheckDecision.variables as SerialCheckVariables)?.deal_whitelist?.ids;
  const { isOptimizelyApiLoaded } = useTemporaryCCContext();
  const dealError = useSelector(errorSelectors.dealGqlError);
  const pizzaError = useSelector(errorSelectors.pizzaGqlError);
  const userIsLocalized = useSelector(localizationSelectors.isLocalized);
  const dispatch = useDispatch();
  const dealId = useSelector(selectDealToDisplay)?.id;
  const router = useRouter();
  const analytics = useAnalytics();
  const isAppInitialized = useApplicationInitialized();
  const isDealBuilder = !!(dealGlobalId || dealId);
  const nationalDeals = dealsData();
  const isGuest = useSelector(userDomainSelectors.isGuest);
  const cartQuantity = useSelector(cartSelectors.quantity);
  const storeDetails = useSelector(localizationSelectors.storeDetails);

  const signInRailOpened = useRef(false);

  const showSignInModal = () => {
    const openSignInRail = () => {
      signInRailOpened.current = true;
      dispatch(openRail(RailType.SIGN_IN));
    };

    const modalDetails = cartQuantity ? CART_EMPTY_WARNING_MODAL : SIGN_IN_WARNING_MODAL;
    const title = modalDetails.TITLE;
    const body = modalDetails.BODY;
    const primaryCtaText = modalDetails.CTAS.PRIMARY;
    const secondaryCtaText = modalDetails.CTAS.SECONDARY;
    const primaryCallback = !cartQuantity ? openSignInRail : undefined;
    const secondaryCallback = cartQuantity ? openSignInRail : undefined;
    dispatch(openModal({
      title,
      body,
      cta: {
        text: primaryCtaText,
        callback: primaryCallback
      },
      altCta: {
        text: secondaryCtaText,
        callback: secondaryCallback
      }
    }));
  };

  const oldIsSerializedCode = ((dealGlobalId?.length === QO_SERIALIZED_CODE_LENGTH)
    || (dealGlobalId?.length === POS_SERIALIZED_CODE_LENGTH && dealGlobalId?.startsWith('PH' || 'ph')));

  const newIsSerializedCode = !!(dealGlobalId?.length === QO_SERIALIZED_CODE_LENGTH
      || dealGlobalId?.length === YUM_SERIALIZED_CODE_LENGTH
      || (dealGlobalId?.includes('-')
        && dealGlobalId?.toLowerCase().startsWith('ph')
        && dealGlobalId?.split('-').pop()?.length === POS_PARSED_SERIALIZED_CODE_LENGTH))
      && !newSerialWhitelistIds?.includes(dealGlobalId);

  const isSerializedCode = isNewSerialCheckEnabled ? newIsSerializedCode : oldIsSerializedCode;

  useEffect(() => {
    if (dealId) {
      telemetry.addCustomEvent('deal-page-shown', {
        'public-code': dealGlobalId ?? null,
        'private-code': dealId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  // Feature flagged useEffect
  useEffect(() => {
    if (!isYumRoutingFixEnabled) return;
    if (typeof hasValidPrivateCode === 'boolean' && storeDetails && isOptimizelyApiLoaded) {
      if (isYumEcomm && isGuest && isSerializedCode) {
        showSignInModal();
      } else if (isYumEcomm && !isYumHeroSideKickRoutingEnabled) {
        // WEB-2189 - Yum ecomm mvp - redirect to base deals page
        dispatch(clearDealToDisplay());
      } else if ((isYumEcomm && isYumHeroSideKickRoutingEnabled && dealGlobalId)) {
        dispatch(setDealToDisplay({ dealId: dealGlobalId, publicCode: dealGlobalId }));
      } else if (hasValidPrivateCode && ssrPrivateCode) {
        dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
      } else if (!hasValidPrivateCode && !ssrError) {
        router.push(Routes.DEALS);
        dispatch(openModal(dealNotAvailableModalDetails));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssrPrivateCode, isGuest, cartQuantity, isOptimizelyApiLoaded]);

  useEffect(() => {
    if (!isYumRoutingFixEnabled) return;
    if (signInRailOpened.current && !isGuest && !isYumEcomm && isOptimizelyApiLoaded && ssrPrivateCode && dealGlobalId) {
      dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
    }
  }, [isYumRoutingFixEnabled, isGuest, isYumEcomm, isOptimizelyApiLoaded, ssrPrivateCode, dealGlobalId, dispatch]);

  // Old useEffect
  useEffect(() => {
    if (isYumRoutingFixEnabled) return;
    if (typeof hasValidPrivateCode === 'boolean') {
      if (isYumEcomm && isSerializedCode && !dealId) {
        showSignInModal();
      } else if (isYumEcomm) {
        // WEB-2189 - Yum ecomm mvp - redirect direct links to base deals page
        dispatch(clearDealToDisplay());
      } else if (hasValidPrivateCode && ssrPrivateCode) {
        dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
      } else if (!hasValidPrivateCode && !ssrError) {
        router.push(Routes.DEALS);
        dispatch(openModal(dealNotAvailableModalDetails));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssrPrivateCode, isGuest, cartQuantity]);

  useClearDealOnStoreChange();
  useClearDealOnRouteChange();
  useHistoryOnBrowserBack();

  useEffect(() => {
    if (dealGlobalId && !userIsLocalized) {
      telemetry.addCustomEvent('national-deals-redirect-from-direct-access', {
        'public-code': dealGlobalId
      });
      router.replace(Routes.DEALS, undefined, { shallow: true });
      if (id) {
        dispatch(openLocalizationRail({ routeTo: `${Routes.DEALS}?id=${id}` }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealGlobalId, userIsLocalized]);

  useEffect(() => {
    if (!userIsLocalized && isAppInitialized) {
      const items = createItemAnalyticsForNationalDeals(nationalDeals);
      analytics.push(() => onNationalDealMenuView(items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLocalized, isAppInitialized]);

  useRedirectErrorHandling(ssrError!, (isDealBuilder) ? Routes.DEALS : Routes.MENU.MENU);

  if (dealError || pizzaError) {
    return <ErrorPage />;
  }

  if (dealId && userIsLocalized
    && ((isYumRoutingFixEnabled && isOptimizelyApiLoaded
      && !(isYumEcomm && isSerializedCode && isGuest))
      || !isYumRoutingFixEnabled)) {
    return (
      <>
        <Header />
        <DealBuilder privateCode={dealId} publicCode={dealGlobalId} />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <CenteredContainer>
        {userIsLocalized
          ? <LocalizedMenu />
          : (
            <Menu
              itemsTestId="national"
              title="Deals"
              items={nationalDeals}
              caloriesDisclaimer
              isNational
              isDeals
            />
          )}
      </CenteredContainer>
      <Footer />
    </>
  );
};
export default Deals;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const { props } = await (initializePage(ctx, 'deals') as any);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const discountCode = (ctx.req as any)?.query?.dealId || '';

  const dealGlobalId = (ctx.req as any)?.query?.id || '';
  const storeId = props.storeNumber;
  const { 'localization-token': localizationToken, 'x-server-env-override': xServerEnv } = ctx.req.cookies;

  if (discountCode) {
    return {
      props: {
        ...props,
        dealGlobalId,
        ssrPrivateCode: discountCode,
        hasValidPrivateCode: true
      }
    };
  }

  if (dealGlobalId && storeId && localizationToken) {
    try {
      const { privateCode } = await dealService.getPrivateCode(
        storeId,
        dealGlobalId,
        localizationToken,
        xServerEnv ?? ''
      );

      const isWhitelisted = !!privateCode && WHITELISTED_DEALS.includes(privateCode);
      const isValidPrivateCode = !!privateCode && (privateCode !== dealGlobalId || isWhitelisted);

      if (privateCode === dealGlobalId && !isWhitelisted) {
        logger.error(
          new Error('User prohibited from visiting deal because public code === private code'),
          { 'public code': dealGlobalId, 'private code': privateCode }
        );
      }

      return {
        props: {
          ...props,
          dealGlobalId,
          ssrPrivateCode: privateCode ?? null,
          hasValidPrivateCode: isValidPrivateCode ?? null
        }
      };
    } catch (error) {
      logger.withoutTelemetry.error('Failed to get deal ID: ', error);
      return {
        props: {
          ...props,
          dealGlobalId,
          ssrError: true
        }
      };
    }
  }
  return {
    props: {
      ...props,
      dealGlobalId
    }
  };
};
