import { Availability, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';

export const availability : Availability[] = [
  {
    days: [3, 4, 7, 1, 2],
    startTime: '08:00:00',
    endTime: '23:00:00',
    occasion: 'DELIVERY',
    status: ''
  },
  {
    days: [5, 6],
    startTime: '08:00:00',
    endTime: '00:00:00',
    occasion: 'DELIVERY',
    status: ''
  },
  {
    days: [3, 4, 7, 1, 2],
    startTime: '08:00:00',
    endTime: '23:00:00',
    occasion: 'CARRYOUT',
    status: ''
  },
  {
    days: [5, 6],
    startTime: '08:00:00',
    endTime: '00:00:00',
    occasion: 'CARRYOUT',
    status: ''
  }
];

// will shift baseDeals id and priority if addedDeals need to be top priority
export const nationalData = (addedDeals: MenuRecipe[], onlyBaseDeals: MenuRecipe[]): MenuRecipe[] => {
  const base = [...onlyBaseDeals];
  const shiftedBaseDeals = base.map((item, index) => {
    const increaseIndex = index + addedDeals.length;
    if (item) {
      return { ...item, id: `${increaseIndex}`, priority: increaseIndex };
    }
    return item;
  });
  return [...addedDeals, ...shiftedBaseDeals];
};
