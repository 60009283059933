import ProductId from '../../common/ProductId';
import {
  BeaconIds, PageViewWithItemsAnalytics
} from '../../dataAnalytics/analyticsTypes';
import { commonMenuPageLoadAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';
import { MenuRecipe } from '../pizza/pizzaMenuTypes';

export const onEnteringDealsMenu = (
  deals: MenuRecipe[]
): PageViewWithItemsAnalytics => ({
  ...commonMenuPageLoadAnalytics('Deals'),
  beacon_id: BeaconIds.MENU.DEALS.INITIAL_LOAD,
  level2_breadcrumb: 'Deals',
  level3_aggregation: 'Deals',
  items: deals.map(({
    outOfStock, name, id, price
  }, index) => ({
    item_list: 'Deals',
    item_category: 'Deals',
    item_index: index,
    item_price: (price ?? 0) / 100,
    item_name: name,
    item_id: new ProductId(id).globalId,
    item_position: `${index + 1}`,
    item_stock: outOfStock ? 'Out of Stock' : 'In Stock'
  }))
});
