import { compose } from '@reduxjs/toolkit';
import { RootState, GqlErrors } from '@/rootStateTypes';

const domain = (state: RootState): GqlErrors => state.presentational.gqlErrors;

const dealGqlError = (errors: GqlErrors) => errors.dealError;
const pizzaGqlError = (errors: GqlErrors) => errors.pizzaError;

export const errorSelectors = {
  dealGqlError: compose(dealGqlError, domain),
  pizzaGqlError: compose(pizzaGqlError, domain)
};
