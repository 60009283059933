import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '@/localization/actions';
import { gqlErrorModalDetails } from '@/common/Modal/modalDetails';
import Routes from '@/router/routes';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

export const ERROR_MESSAGE = 'Page being accessed failed to load, redirected to fallback route.';

export const useHandleSuccessMenuLoadAfterError = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [hasHandledPrevError, setHasHandledPrevError] = useState(false);
  const hadPrevPageError = !!router?.query?.error;

  return (extraConditional = true, callback?: () => void) => {
    if (hadPrevPageError && !hasHandledPrevError && extraConditional) {
      setHasHandledPrevError(true);
      dispatch(openModal(gqlErrorModalDetails));
      if (callback) callback();
    }
  };
};

export const useRedirectErrorHandling = (
  hasError: boolean, fallbackRoute: string, callback?: () => void
): boolean => {
  const dispatch = useDispatch();
  const router = useRouter();
  const hadPrevPageError = !!router.query.error;
  const redirectPath = hadPrevPageError ? Routes.HOME : fallbackRoute;
  const [hasHandledError, setHasHandledError] = useState(false);

  useEffect(() => {
    if (hasError) {
      const failedRoute = router.asPath;

      router.push(
        { pathname: redirectPath, query: { error: true, failedRoute } },
        redirectPath
      ).then(() => {
        if (redirectPath === Routes.HOME) dispatch(openModal(gqlErrorModalDetails));
        if (callback) callback();
      });

      const errorInfo = { failedRoute, redirectedTo: redirectPath };
      logger.withoutTelemetry.error(ERROR_MESSAGE, errorInfo);
      telemetry.addNoticeError(new Error(ERROR_MESSAGE), errorInfo);

      setHasHandledError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return hasHandledError;
};
